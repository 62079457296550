export const getHomePageUrl = (lang: string) => {
  if (lang === "en") return "/"
  return `/${lang}/`
}

export const getSearchUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "search")


}

export const getSearchAuthorsUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "search/authors")



}

export const getSearchCategoriesUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "search/categories")



}

export const getPopularUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "popular")




}

export const getPrivacyUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "legal/privacy")



}

export const getTermsUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "legal/terms-of-service")


}

export const getCookiesUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "legal/cookies")



}

export const normalizeI18nUrl = (lang: string, url: string) => {
  if (lang === "en") return `/${url}/`
  return `/${lang}/${url}/`
}

export const getAuthorUrl = (lang: string, username: string) => {
  return normalizeI18nUrl(lang, "@" + username)
}

export const getBookmarksUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "bookmarks")

}

export const getBookmarksLikesUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "bookmarks/likes")

}

export const getBookmarksFollowedUrl = (lang: string) => {
  return normalizeI18nUrl(lang, "bookmarks/followed")
}
